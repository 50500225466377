



































import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";
import { Roles } from "@/models/entities/role.interface";
const authModule = getModule(AuthModule, store);

@Component
export default class ErrorMessage extends Vue {
  @Prop({ required: false, default: "generico" }) private code: string | number;
  @Prop({ required: true }) private message: string;
  @Prop({ required: false }) private submessage: string;

  get isNumber(): boolean {
    return !isNaN(Number(this.code));
  }

  private home(): string {
    const roles = new Set(authModule.roles);
    if (roles.has(Roles.EMERGENCIES_ACCESS)) return "/emergencies";
    if (roles.has(Roles.ANOMALIES_ACCESS)) return "/anomalies";
    if (roles.has(Roles.REGULAR_ACCESS)) return "/regular";
    if (roles.has(Roles.ARCHIVED_ACCESS)) return "/archived";
    if (roles.has(Roles.USERS_ACCESS)) return "/users";
    return "/error";
  }
}
