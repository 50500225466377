








import ErrorMessage from "@/components/ErrorMessage.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    "error-message": ErrorMessage,
  },
})
export default class Error extends Vue {
  @Prop({ required: false }) private code: string | number;
  @Prop({ required: false, default: "Hai incontrato un errore imprevisto." })
  private message: string;
  @Prop({
    required: false,
    default: `Contattaci in caso di problemi, oppure ritorna alla pagina home.`,
  })
  private submessage: string;

  private created() {
    if (
      this.$vuetify.breakpoint.lgAndUp &&
      this.$route.name === "MobileError"
    ) {
      this.$router.replace({ path: "/" });
    }
  }
}
